import { List, ListItem } from "@material-ui/core";
import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import clsx from "clsx";
import GeneralErrorPage from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { Form, Formik } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import _ from "lodash";
import { observer } from "mobx-react";
import { TokenVerificationStatus } from "models/userManagement/userManagementModels";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import SetApiValidationErrors from "validation/HelperComponents/SetApiValidationsErrors";
import {
    ResetPasswordErrorType,
    resetPasswordValidationSchema,
} from "validation/ValidationSchemas/resetPasswordValidationSchema";
import { validationHelpers } from "validation/helper-functions";
import { ValidationError } from "yup";
import { loginStyles } from "../LoginStyles";

const PasswordRuleListItem = ({ error, text }: { error: boolean; text: string }) => {
    const classes = loginStyles();

    return (
        <>
            <ListItem disableGutters className={classes.paddingTopZero}>
                {error ? (
                    <LynxIcon name="bulletEmpty" className={classes.listIconFill} />
                ) : (
                    <LynxIcon name="checkSmall" className={classes.successIcon} />
                )}
                <LynxTypography className={classes.listItem}>{text}</LynxTypography>
            </ListItem>
        </>
    );
};

const PasswordResetForm = observer(() => {
    const classes = loginStyles();
    const { userStore } = useStore();
    const navigate = useNavigate();
    const location = useLocation();

    const [minLengthError, setMinLengthError] = useState(true);
    const [atLeastOneLowercaseError, setAtLeastOneLowercaseError] = useState(true);
    const [atLeastOneUppercaseError, setAtLeastOneUppercaseError] = useState(true);
    const [atLeastOneNumericError, setAtLeastOneNumericError] = useState(true);

    const validationSchema = resetPasswordValidationSchema();
    const loadingFlag = userStore.progressFlags.resetPassword;
    const params = new URLSearchParams(location.search);
    const token = params.get("recoveryToken");

    const setErrorsObj = {
        [ResetPasswordErrorType.MinLength]: setMinLengthError,
        [ResetPasswordErrorType.AtLeastOneLowercase]: setAtLeastOneLowercaseError,
        [ResetPasswordErrorType.AtLeastOneUppercase]: setAtLeastOneUppercaseError,
        [ResetPasswordErrorType.AtLeastOneNumeric]: setAtLeastOneNumericError,
    };

    const handleResponse = (statusCode: number, errors: ApiValidationError[]) => {
        if (statusCode === 200) {
            userStore.setPasswordResetFlag(true);
            navigate(routes.passwordSuccessfullyReset, { replace: true });
        } else if (statusCode === 400) {
            userStore.setResetPasswordErrors(
                validationHelpers.generateFormikErrorsFromApi<{ password: string; confirmPassword: string }>(errors)
            );
        }
    };

    const onSubmit = (values: { password: string; confirmPassword: string }) =>
        userStore.resetPassword(token!, values.password, handleResponse);

    const handlePasswordChange = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldError: (field: string, message: string | undefined) => void
    ) => {
        const promise = validationSchema.validateAt(
            "password",
            { password: e.target.value, confirmPassword: "" },
            { abortEarly: false }
        );

        promise.then(
            () => {
                minLengthError && setMinLengthError(false);
                atLeastOneLowercaseError && setAtLeastOneLowercaseError(false);
                atLeastOneUppercaseError && setAtLeastOneUppercaseError(false);
                atLeastOneNumericError && setAtLeastOneNumericError(false);
            },
            (err) => {
                if (err.name === "ValidationError") {
                    const errors = err.inner as ValidationError[];

                    _.forIn(setErrorsObj, (setError, key) => {
                        const error = errors.find((x) => x.type === key);

                        setError(!!error);
                    });
                }
            }
        );

        setFieldValue("password", e.target.value);
        setFieldError("password", undefined);
        setFieldError("confirmPassword", undefined);
    };

    const renderContentBasedOnVerificationStatus = () => {
        if (userStore.progressFlags.verifyRecoveryToken) {
            return (
                <LoadingIndicator
                    className={clsx(classes.loadingIndicator, classes.marginVerticalAutoHorizontalZero)}
                />
            );
        }

        switch (userStore.recoveryTokenVerificationStatus) {
            case TokenVerificationStatus.Valid:
                return (
                    <div className={classes.marginVerticalAutoHorizontalZero}>
                        <LynxTypography variant="h2" className={classes.marginBottomMedium}>
                            New Password
                        </LynxTypography>

                        <LynxTypography id="password-reset-rules">Password must contain the following:</LynxTypography>

                        <List aria-labelledby="password-reset-rules">
                            <PasswordRuleListItem error={minLengthError} text="Minimum 8 characters" />
                            <PasswordRuleListItem error={atLeastOneLowercaseError} text="At least 1 lowercase letter" />
                            <PasswordRuleListItem error={atLeastOneUppercaseError} text="At least 1 uppercase letter" />
                            <PasswordRuleListItem error={atLeastOneNumericError} text="At least one numeric" />
                        </List>

                        <Formik
                            initialValues={{ password: "", confirmPassword: "" }}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                        >
                            {({ setFieldValue, setFieldError }) => (
                                <Form className={classes.marginTopSmall}>
                                    <SetApiValidationErrors storeErrors={userStore.resetPasswordErrors} />
                                    <LynxInputForm
                                        autoComplete="new-password"
                                        name="password"
                                        label="New Password"
                                        placeholder="Enter new password"
                                        passwordInput
                                        formControlClassName={classes.marginBottomVerySmall}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            handlePasswordChange(e, setFieldValue, setFieldError);
                                        }}
                                    />

                                    <LynxInputForm
                                        autoComplete="new-password"
                                        name="confirmPassword"
                                        label="Repeat New Password"
                                        placeholder="Repeat new password"
                                        passwordInput
                                        formControlClassName={classes.marginBottomMedium}
                                    />

                                    <LynxButton
                                        size="large"
                                        type="submit"
                                        className={classes.buttonFullWidth}
                                        loading={loadingFlag}
                                        disabled={loadingFlag}
                                    >
                                        Set new password
                                    </LynxButton>
                                </Form>
                            )}
                        </Formik>
                    </div>
                );
            case TokenVerificationStatus.CannotValidate:
                return <GeneralErrorPage sectionStyles={classes.errorSectionStyles} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!token) {
            navigate(routes.login);
        } else {
            userStore.verifyRecoveryToken(token);
        }
    }, []);

    useEffect(() => {
        if (
            userStore.recoveryTokenVerificationStatus === TokenVerificationStatus.Invalid ||
            userStore.recoveryTokenVerificationStatus === TokenVerificationStatus.Expired
        ) {
            navigate(routes.recoveryTokenInvalid, { replace: true });
        }
    }, [userStore.recoveryTokenVerificationStatus]);

    return renderContentBasedOnVerificationStatus();
});

export default PasswordResetForm;
