import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { DismissPopupsButton } from "components/DismissPopupsButton/DismissPopupsButton";
import { oktaAuth } from "oktaAuth";
import { bypassErrorInterceptor } from "./apiRoutes";
import { envConstants } from "../env-constants";
import { EventsService } from "./services/EventsService";
import { StabilityFormsService } from "./services/StabilityFormsService";
import { UsersService } from "./services/user-management/UsersService";
import { CustomersService } from "./services/user-management/CustomersService";
import { GroupsService } from "./services/user-management/GroupsService";
import { PermissionsService } from "./services/user-management/PermissionsService";
import { CustomersDataService } from "./services/CustomersDataService";
import { BaseResponse } from "./models/sharedModels/ResponseModels";
import { NotificationType } from "models/shared/NotificationType";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { AuditService } from "./services/AuditService";
import { CommonService } from "./services/CommonService";
import { unknownErrorText } from "helpers/handleErrors";

export const torAxios = axios.create({
    baseURL: envConstants.REACT_APP_TOR_AXIOS_BASE_URL,
});

export const torAxiosIgnoreErrors = axios.create({
    baseURL: envConstants.REACT_APP_TOR_AXIOS_BASE_URL,
});

torAxios.interceptors.request.use(
    (config) => {
        if (config.headers.Authorization !== "") {
            config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`;
        } else {
            // if Authorization header was set manually then remove  header completely. Required for AWS presignedurls to avoid exception;
            delete config.headers.Authorization;
        }
        return config;
    },
    (error) => {
        console.log("Request error: " + error);
    }
);

// TODO: need to refactor error handling, we should be able to show validation errors as popup
torAxios.interceptors.response.use(
    (response) => response,
    ({ response, config }: AxiosError<BaseResponse>) => {
        if (response?.data?.isValidationError || bypassErrorInterceptor(response, config)) {
            return response;
        }

        if (response?.status === 401) {
            return response;
        }

        if (response?.data?.notDisplayPopup) {
            return response;
        }

        if (response?.status === 404) {
            window.location.replace("/pageNotFound");
        }

        if (!toast.isActive("dismissAll")) {
            toast(<DismissPopupsButton />, {
                toastId: "dismissAll",
            });
        }

        displayPopupNotification(NotificationType.ERROR, unknownErrorText);

        return response;
    }
);

torAxiosIgnoreErrors.interceptors.request.use((config) => {
    if (config.headers.Authorization !== "") {
        config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`;
    } else {
        // if Authorization header was set manually then remove  header completely. Required for AWS presignedurls to avoid exception;
        delete config.headers.Authorization;
    }
    return config;
});

torAxiosIgnoreErrors.interceptors.response.use(
    (response) => response,
    ({ response }: AxiosError<BaseResponse>) => {
        if (response?.status === 404) {
            window.location.replace("/pageNotFound");
        }

        return response;
    }
);

export const services = {
    Events: new EventsService(),
    StabilityForms: new StabilityFormsService(),
    Users: new UsersService(),
    Customers: new CustomersService(),
    Groups: new GroupsService(),
    Permissions: new PermissionsService(),
    CustomersData: new CustomersDataService(),
    Audit: new AuditService(),
    Common: new CommonService(),
};
